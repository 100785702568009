import React from "react"
import PropTypes from "prop-types"

import "./layout.css"


const Project = ({project}) => {
    let message = (project.public) ? 'Repo' : '(Project in Private Repo)'

    return (
        <>
        <div id={project.title} className="spacer"/>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '2rem',
            border: '2px solid black'
        }}>
            <div>
                <h1 style={{
                    marginBottom: '0',
                }}>{project.title}</h1>
                <h5 style={{
                    marginTop: '0',
                }}>Last Updated: {project.updated}</h5>
            </div>
            <p>{project.desc}</p>
            <a href={project.link} target="_blank">{message}</a>
            <div>
                <img class='project-img' src={project.img}/>
            </div>
            
        </div>
        </>
    )
}



export default Project