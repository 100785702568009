import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/project"

const projects = [
  {
    title: 'Snake',
    updated: 'June 2018',
    desc: 'This is my recreation of Snake. This was my first ever JavaScript project and my primary focus was to learn the fundamentals of JS and HTML/CSS. \
            It heavily relied on the <canvas> element to function. It plays the way traditional snake does and has a few settings that can be changed by the user.',
    img: '../../snake.png',
    link: 'https://github.com/griffin104/Snake-Game',
    last: false,
    public: true
  },
  {
    title: 'Pong',
    updated: 'July 2018',
    desc: 'This is my recreation of Pong. This game supports both 1 and 2 players. However, the computer in the 1 player mode is unbeatable and just for practice. \
            Additionally, the angle that the ball deflects off the paddle at is randomized.',
    img: '../../pong.png',
    link: 'https://github.com/griffin104/PongGame',
    last: false,
    public: true
  },
  {
    title: 'Platformer',
    updated: 'September 2018',
    desc: 'My first "original" game! The premise of this game is really simple, just grab the coins and go to the exit. What\'s special about this game is that the player \
            is able to make and play their own levels. While making this game, I also fiddled around with jQuery for some animations. Development of this game is currently on \
            hiatus.',
    img: '../../platformer.png',
    link: 'https://github.com/griffin104/Platformer',
    last: false,
    public: true
  },
  {
    title: 'Basketball DB',
    updated: 'December 2019',
    desc: 'This is a school project I did for my Databases and SQL class. It is a database that shows various stats for players, teams, and venues for the 2018 season. \
            It comes included with some useful views such as generating everyones rating and listing the players who changed teams and had an increase in points scored.',
    img: '../../basketball.png',
    link: 'https://github.com/griffin104/basketball-db',
    last: false,
    public: true
  },
  {
    title: 'Destiny Weapon Checklist',
    updated: 'July 2020',
    desc: 'My most current project is an app that utilizes Bungie\'s API to retrieve game information specific to the user and display it in an \
           organized way for the user. However, this project is still in early development and doesn\'t have a working version.',
    img: '../../destiny.png',
    last: true,
    public: false
  }
]



const ProjectsPage = () => (
  <body>
   <Layout>
    <SEO title="Projects Page"></SEO>
    <div className="spacer"/>
      {projects.map(project => (
        <Project project={project}/>
      ))}
  </Layout>
  </body>
)

export default ProjectsPage